/* NavBar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f1f1f;
    padding: 5px 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Logo and Brand Text Styling */
.navbar-logo-section {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between logo and text */
}

.navbar-logo {
    width: 50px; /* Adjusted logo size for balance with text */
    height: auto;
}

.navbar-brand-text {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 1px;
}

/* Navbar Links */
.navbar-links {
    list-style-type: none;
    display: flex;
    gap: 15px;
}

.navbar-links li {
    display: inline;
}

.navbar-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.navbar-links a:hover {
    text-decoration: underline;
}

/* Connect Wallet Button */
.connect-wallet-btn {
    background-color: #6a00ff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.connect-wallet-btn:hover {
    background-color: #8200ff;
}

/* Wallet Info Styling */
.wallet-info {
    color: #ffffff;
    font-weight: bold;
    background-color: #2c3e50;
    padding: 8px 16px;
    border-radius: 8px;
}
