/* Style for the widget container */
.swap-widget-container {
    background-color: rgba(34, 34, 34, 0.9); /* Dark background for dark mode */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 500px;
}

/* Style for the loading state */
.swap-widget-loading {
    color: white;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
}

/* Style for the widget tabs */
.widget-tabs {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
}

.widget-tab {
    background-color: #444;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.widget-tab:hover {
    background-color: #666;
}

.widget-tab.active {
    background-color: #fff;
    color: #000;
    font-weight: bold;
}

/* Style for the widget's inner content */
.swap-widget-inner {
    color: white;
    font-size: 18px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}
