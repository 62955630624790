@tailwind base;
@tailwind components;
@tailwind utilities;

/* Style for the entire body */
body {
  background-image: url('./assets/background.jpg'); /* Background from assets */
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* Style for the main app container */
.App {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Style for the logo */
.logo {
  width: 200px;
  margin-bottom: 20px;
}

/* Title styling */
.header-title {
  color: #f0f0f0;
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

/* Container for widgets and tabs */
.swap-container {
  display: flex;
  flex-direction: column; /* Stacking in column for tabs */
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Tabs Container */
.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Individual Tab Styling */
.tab {
  background: linear-gradient(145deg, #4c4c4c, #2d2d2d); /* Cool gradient effect */
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  border: none; /* No border */
  transition: background 0.3s ease, transform 0.2s ease;
}

.tab:hover {
  background: linear-gradient(145deg, #6c6c6c, #1d1d1d); /* Darker gradient on hover */
  transform: translateY(-2px); /* Slight raise effect on hover */
}

.tab.active {
  background-color: #f0f0f0; /* Highlight the active tab */
  color: black;
  font-weight: bold;
}

/* Container for each widget */
.widget-container {
  background-color: rgba(34, 34, 34, 0.9); /* Dark background for dark mode */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 12px;
  width: 400px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

/* Custom button for the In-house Algorithm section */
.invest-btn {
  background: linear-gradient(145deg, #6a00ff, #3a0088); /* Gradient for button */
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
}

.invest-btn:hover {
  background: linear-gradient(145deg, #8200ff, #4d0099); /* Hover effect */
  transform: translateY(-2px); /* Slight raise on hover */
}

/* Media query for smaller screens */
@media (max-width: 900px) {
  .swap-container {
    flex-direction: column;
  }

  .widget-container {
    margin-bottom: 20px;
  }
}
