/* TOS.css */

/* Container to center the popup in the middle of the screen */
.tos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it’s on top of other elements */
  }
  
  /* Popup box */
  .tos-popup {
    background-color: #ffffff; /* White background for readability */
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    max-width: 800px;
    width: 90%; /* Make it responsive */
    text-align: left;
  }
  
  /* Header styling */
  .tos-popup h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Section headers */
  .tos-popup h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #3498db;
  }
  
  /* Paragraph styling */
  .tos-popup p {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #666;
  }
  
  /* Ensure links in the TOS are properly styled */
  .tos-popup a {
    color: #3498db;
    text-decoration: none;
  }
  